<template>
  <v-row>
    <v-row justify="center" max-width="344">
      <v-col cols="10" sm="10">
        <v-form
          ref="form"
          v-model="isvalid"
          lazy-validation
        >
          <v-card>
						<v-card-title align="left" class="headline primary report-title">
							<h5>Profitability Request</h5>
						</v-card-title> 
            <br>
            <v-container>
							<v-layout wrap>
                <v-flex md1>
                </v-flex>
								<v-flex md2>
									<v-subheader>
										<h3>Type:</h3>
									</v-subheader>
								</v-flex>
								<v-flex xs6 sm4 md9>
									<v-radio-group v-model="item.type" row dense>
										<v-radio label="Monthly" value="ftm"></v-radio>
										<v-radio label="As Of" value="daily"></v-radio>
									</v-radio-group>
								</v-flex>
							</v-layout>
              <v-layout wrap>
                <v-flex md1>
                </v-flex>
                <v-flex xs6 sm3 md2>
									<v-subheader>
										<h3 v-if="item.type === 'ftm'">Year:</h3>
										<h3 v-else>Date From:</h3>
									</v-subheader>
                </v-flex>
								<v-flex xs6 sm4 md3>
									<v-select
									v-if="item.type === 'ftm'"
                  :items="year"
									dense
                  v-model="item.year"
                  :rules="[v => !!v || 'Please select Year']"
                  required
                  ></v-select>
									<v-menu
										v-else
										v-model="from_date"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												dense
												v-model="item.date_from"
												required
												:rules="date_rules"
												return-masked-value
												v-on="on"
												mask="YYYY-MM-DD"
												placeholder="YYYY-MM-DD">
											</v-text-field>
										</template>
										<v-date-picker
											color="primary"
											v-model="item.date_from"
										></v-date-picker>
									</v-menu>
								</v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex md1>
                </v-flex>
                <v-flex xs6 sm3 md2>
									<v-subheader>
										<h3 v-if="item.type === 'ftm'">Month:</h3> 
										<h3 v-else>Date To:</h3>
									</v-subheader>
                </v-flex>
                <v-flex xs6 sm4 md3>
									<v-select
									v-if="item.type === 'ftm'"
                  :items="periodNo"
									dense
                  v-model="item.month"
                  :rules="[v => !!v || 'Please select Month']"
                  required
                  ></v-select>
									<v-menu
										v-else
										v-model="to_date"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												dense
												v-model="item.date_to"
												required
												:rules="date_rules"
												return-masked-value
												v-on="on"
												mask="YYYY-MM-DD"
												placeholder="YYYY-MM-DD">
											</v-text-field>
										</template>
										<v-date-picker
											color="primary"
											v-model="item.date_to"
										></v-date-picker>
									</v-menu>
								</v-flex>
              </v-layout><v-layout wrap>
                <v-flex md1>
                </v-flex>
                <v-flex xs6 sm3 md2>
									<v-subheader>
										<h3>Currency:</h3> 
									</v-subheader>
                </v-flex>
                <v-flex xs6 sm4 md3>
									<v-autocomplete
										v-model="item.currency_id"
										:items="currencies"
										v-on:focus="getCurrency"
										editable
										dense
										hide-details
										item-text="cur_desc"
										item-value="id"
									></v-autocomplete>
								</v-flex>
              </v-layout>
							<br>
              <v-layout>
                <v-flex md1>
                </v-flex>
                <v-btn dark color="blue" width="200" @click="generate">Generate</v-btn>
              </v-layout>
            </v-container> 
            <br>
            <br>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	data: () => ({
		from_date: false,
		to_date: false,
		date_from: '',
		date_to: '',
		year: [],
		item: {type: 'ftm', currency_id: 0},
		date_rules: [
			v => !!v || 'Date is required',
		],
		currency_rules: [
			v => !!v || 'Currency is required',
		],
		isvalid: false,
		currencies: [{id: 0, cur_desc: 'Overall Peso'}]
	}),
	computed: {
		...mapGetters({
			periodNo: 'acctperiodmain/periodNo',
			currency: 'currency/currencies'
		})
	},
	mounted(){
		this.$store.dispatch('currency/getCurrencies');
		this.getYear();
		this.getCurrency();
	},
	methods: {
		getCurrency(){
			this.currency.map(det => {
				this.currencies.push(det);
			});
		},
		getYear() {
			let year = new Date().getFullYear();
			let data = [];
			var i;
			data.push({value: year, text: year});
			for (i = 1; i < 10; i++) {
				let det = year - i;
				det.toString();
				data.push({value: det, text: det});
			}
			this.year = data;
		},
		generate() {
			//this.item.month.toString();
			this.isvalid = this.$refs.form.validate();
			if(this.isvalid){
				let routeData = this.$router.resolve({path: '/report-profitability/'+btoa(JSON.stringify(this.item)), data: this.item});
				window.open(routeData.href, '_blank'); 
			}
		}
	}
};
</script>
<style>
.report-title {
	color: #FFFFFF;
}
</style>