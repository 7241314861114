<template>
	<div class="home">
		<profitability-request></profitability-request>
	</div>
</template>

<script>
import profitability from '@/components/pages/report/profitability/profitabilityRequest.vue';

export default {
	components: {
		'profitability-request': profitability,
	}
};
</script>

<style>

</style>
